import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

const globalMixIn = {
	mounted() {
		// ページタイトルをDOM（document.title）で設定
		let { title } = this.$options;
		if (title) {
			document.title = title + " - ASSIST BMW tuning";
		}
	},
};
Vue.mixin(globalMixIn);

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#apps");

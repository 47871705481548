import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("../views/Home.vue"),
	},
	// Tuned Car
	{
		path: "/assist-m4-f82",
		name: "ASSIST-M4-F82",
		component: () => import("../views/concept-cars/ASSIST-M4-F82.vue"),
	},
	{
		path: "/assist-m3-e92",
		name: "ASSIST-M3-E92",
		component: () => import("../views/concept-cars/ASSIST-M3-E92.vue"),
	},
	{
		path: "/assist-z4-g29",
		name: "ASSIST-Z4-G29",
		component: () => import("../views/concept-cars/ASSIST-Z4-G29.vue"),
	},
	/*{
		path: "/assist-m3-e92-47l",
		name: "ASSIST-M3-E92-4.7L",
		component: () => import("../views/concept-cars/ASSIST-M3-E92-47L.vue"),
	},*/
	{
		path: "/tuned/assist-cars",
		name: "TunedCars",
		component: () => import("../views/concept-cars/TunedCarsAssist.vue"),
	},
	{
		path: "/tuned/user-cars",
		name: "TunedCars",
		component: () => import("../views/concept-cars/TunedCarsUser.vue"),
	},
	{
		path: "/tuned/:id",
		name: "TunedCar",
		component: () => import("../views/concept-cars/TunedCarTemplate.vue"),
	},

	// Maintenance
	{
		path: "/vehicle_inspection",
		name: "VehicleInspection",
		component: () => import("../views/maintenance/VehicleInspection.vue"),
	},
	{
		path: "/periodic_inspection",
		name: "PeriodicInspection",
		component: () => import("../views/maintenance/PeriodicInspection.vue"),
	},

	// Store
	{
		path: "/finance",
		name: "Finance",
		component: () => import("../views/store/Finance.vue"),
	},
	{
		path: "/cars",
		name: "Cars",
		component: () => import("../views/store/Cars.vue"),
	},

	// Aboutus
	{
		path: "/company",
		name: "Company",
		component: () => import("../views/aboutus/Company.vue"),
	},
	{
		path: "/staff",
		name: "Staff",
		component: () => import("../views/aboutus/Staff.vue"),
	},
	{
		path: "/recruit",
		name: "Recruit",
		component: () => import("../views/aboutus/Recruit.vue"),
	},
	/*{
		path: "/recruit/general",
		name: "RecruitGeneral",
		component: () => import("../views/aboutus/recruit/General.vue"),
	},*/
	{
		path: "/recruit/technical",
		name: "RecruitTechnical",
		component: () => import("../views/aboutus/recruit/Technical.vue"),
	},
	{
		path: "/calendar",
		name: "Calendar",
		component: () => import("../views/aboutus/Calendar.vue"),
	},
	{
		path: "/access",
		name: "Access",
		component: () => import("../views/aboutus/Access.vue"),
	},

	// other
	{
		path: "/*",
		redirect: "/",
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
